import { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import getAuthHeaders from "../../utils/getAuthHeaders";
import Loading from "../Loading";

import "./PickupTimes.css";

const PickupTimes = () => {
  const { user } = useAuthContext();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isPending, setPending] = useState(true);

  useEffect(() => {
    if (user) {
      const getLocation = async () => {
        try {
          const authHeader = await getAuthHeaders(user);

          let locationUrl = `/api/regions/${user.uid}`;

          if (process.env.NODE_ENV === "development") {
            locationUrl = `${process.env.REACT_APP_NETWORK}:4000${locationUrl}`;
          }

          fetch(locationUrl, { headers: authHeader }).then(async (res) => {
            const json = await res.json();
            setData(json);
            setPending(false);
          });
        } catch (err) {
          console.log(err.message);
          setError(err.message);
          setPending(false);
        }
      };

      getLocation();
    }
  }, [user]);

  return (
    <div>
      {data && <h1>Pick-up Times for {data.currentOrder}</h1>}
      {data && data.locationNotes && (
        <p className="locationNotes">{data.locationNotes}</p>
      )}
      {isPending && <Loading />}
      {data && (
        <table className="center">
          <tbody>
            <tr key="header">
              <th>Location</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
            {data.pickupTimes.map((location) => {
              return location.windows.map((window) => {
                return (
                  <tr
                    className={window.open ? "openSlot" : "fullSlot"}
                    key={location.location + window.time}
                  >
                    <td>{location.location}</td>
                    <td>{window.time}</td>
                    <td>{window.open ? "Open" : "Full"}</td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default PickupTimes;
