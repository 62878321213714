import logo from "../images/Green@small-bold.png";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const { error, login } = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email.toLowerCase(), password);
  };

  return (
    <div className="login grid">
      <img src={logo} alt="" className="logo" />
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="Email"
          autoComplete="on"
        />
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="Password"
          autoComplete="on"
        />
        <button className="login-button">Log In</button>
        <button onClick={() => navigate("/signup")}> Sign Up</button>
        {error && <p className="login-error">{error}</p>}
        <p className="signup-note">
          {<Link to="/login/reset-password">Forgot your password?</Link>}{" "}
        </p>
      </form>
      <p className="login-serving-parsons">
        Serving Parsons, Kansas and Surrounding Communities
      </p>
      <div className="login-benefits-flex">
        <p className="login-benefits-child">No Minimum Order</p>
        <p className="login-benefits-child">Low Prices</p>
        <p className="login-benefits-child">No Membership Fees</p>
      </div>
    </div>
  );
};

export default Login;
