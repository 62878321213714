import { useState, useEffect } from "react";

//styles
import "./Pickup.css";

//hooks
import { useAuthContext } from "../../hooks/useAuthContext";

//utilities
import getAuthHeaders from "../../utils/getAuthHeaders";

//components
import Loading from "../../components/Loading";

export default function Pickup({
  setDisableContinue,
  setFulfillmentObject,
  location,
}) {
  const [fulfillmentDate, setFulfillmentDate] = useState("");
  const [slotRef, setSlotRef] = useState(null);
  const [customPickup, setCustomPickup] = useState(false);
  const [customPickupNotes, setCustomPickupNotes] = useState("");
  const [alternatePickupPerson, setAlternatePickupPerson] = useState("");
  const [previousOrder, setPreviousOrder] = useState("");
  const [isPending, setIsPending] = useState(true);
  const { user, authIsReady } = useAuthContext();
  const [taxRate, setTaxRate] = useState(null);
  const [taxExempt, setTaxExempt] = useState(false);
  const pickupTimeArray = handlePickupTimes(
    location ? location.pickupTimes : null
  );
  const pickupLocationArray = location ? location.locations : null;
  const orderMonth = location ? location.currentOrder : null;

  useEffect(() => {
    if (fulfillmentDate.length > 0) {
      const fulfillmentObject = {
        type: "Pickup",
        fulfillmentDate,
        slotRef,
        taxRate,
        customPickup,
        customPickupNotes,
        orderMonth,
        additionalOrder: "false",
        alternatePickupPerson,
        taxExempt,
      };

      setFulfillmentObject(fulfillmentObject);
    }
  }, [
    customPickup,
    customPickupNotes,
    pickupLocationArray,
    setDisableContinue,
    fulfillmentDate,
    slotRef,
    taxRate,
    taxExempt,
  ]);

  useEffect(() => {
    if (user && orderMonth) {
      const getPreviousOrder = async () => {
        try {
          const authHeader = await getAuthHeaders(user);
          let url = `/api/orders/user/${user.uid}`;

          if (process.env.NODE_ENV === "development") {
            url = `${process.env.REACT_APP_NETWORK}:4000${url}`;
          }

          const requestOptions = {
            method: "POST",
            headers: {
              ...authHeader,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ orderMonth }),
          };

          fetch(url, requestOptions).then(async (res) => {
            const previousOrders = await res.json();

            if (previousOrders.length >= 1) {
              setPreviousOrder(previousOrders[0].fulfillment.fulfillmentDate);
              setFulfillmentObject({
                ...previousOrders[0].fulfillment,
                additionalOrder: true,
              });
              setDisableContinue(false);
            }
            setIsPending(false);
          });
        } catch (err) {
          console.log(err.message);
          setIsPending(false);
        }
      };

      getPreviousOrder();
    }
  }, [user, setPreviousOrder, orderMonth]);

  const handleCustomPickup = () => {
    if (customPickup) {
      setCustomPickup(false);
      setDisableContinue(true);
    } else {
      const locationObject = pickupLocationArray.find(
        (obj) => obj.name === "Parsons"
      );
      setFulfillmentDate("Custom pickup");
      setSlotRef({ id: 0, slotNum: 0 });
      setCustomPickup(true);
      setDisableContinue(false);

      //if uid is [inclulded], customer is tax exempt and taxes should be zero
      if (
        user &&
        (user.uid === "I23s9GEInHNWN1u1nViCZeKvGVu2" ||
          user.uid === "ukVoZnWYOUeE0rbrnDurEoqJaSw2")
      ) {
        setTaxExempt(true);
        setTaxRate({ food: 0, nonFood: 0 });
      } else {
        setTaxRate(locationObject.taxRate);
      }
    }
  };

  const handleLocation = (selection) => {
    const { pickupTime, location, slotRef } = selection;
    setFulfillmentDate(pickupTime);
    setSlotRef(slotRef);
    setCustomPickup(false);
    setDisableContinue(false);

    /* the find() method is called on the pickupLocationArray, 
    and it takes a callback function as an argument. 
    The callback function checks if the "name" property of the
    object passed to it equals [locationName].
    If it does, then the find() method returns that object. */

    const locationObject = pickupLocationArray.find(
      (obj) => obj.name === location
    );

    if (
      user &&
      (user.uid === "I23s9GEInHNWN1u1nViCZeKvGVu2" ||
        user.uid === "ukVoZnWYOUeE0rbrnDurEoqJaSw2")
    ) {
      setTaxExempt(true);
      setTaxRate({ food: 0, nonFood: 0 });
    } else {
      setTaxRate(locationObject.taxRate);
    }
  };

  return (
    <div className="pickup">
      <h3>Schedule a pick-up time</h3>
      {!isPending && previousOrder.length <= 0 && (
        <p>
          Please carefully note the pick-up times and locations. 🧐 They may be
          different from your last order!
        </p>
      )}
      {isPending && <Loading />}
      {!isPending && previousOrder.length > 0 && (
        <>
          <p>Your scheduled pick-up time is:</p>
          <p>
            <b> {previousOrder}</b>
          </p>
          <p>Please continue to the next page to complete your order.</p>
        </>
      )}
      {!isPending && previousOrder.length <= 0 && (
        <>
          <form>
            <p>Please select a pick-up time and location:</p>
            {pickupTimeArray &&
              pickupTimeArray.map((option) => {
                const { location, time, slotRef } = option;
                const locationText = `${location} | ${time}`;
                return (
                  <div key={locationText}>
                    <input
                      type="radio"
                      id={locationText}
                      value={locationText}
                      onChange={(e) =>
                        handleLocation({
                          pickupTime: e.target.value,
                          location,
                          slotRef,
                        })
                      }
                      name="option"
                      checked={locationText === fulfillmentDate ? true : false}
                    />
                    <label htmlFor={locationText}>{locationText}</label>
                  </div>
                );
              })}
          </form>
          <p>Pick-up Locations:</p>
          {pickupLocationArray &&
            pickupLocationArray.map((location) => {
              const { name, pickupLocation } = location;
              return (
                <div key={name}>
                  <p>
                    •<b>{name}</b> - {pickupLocation}
                  </p>
                </div>
              );
            })}
          <h3>Teamwork makes the dream work</h3>
          <div>
            <hr />
            <p>
              <i>
                Is your daughter, neighbor, or best friend placing an order too?
                Plan to have her pick up your order? Let us know here and we'll
                pack your orders together:
              </i>
            </p>
            <input
              className="pickupNotes"
              type="text"
              placeholder="Enter pickup person"
              onChange={(e) => setAlternatePickupPerson(e.target.value)}
            />
          </div>
          <div className="customPickup">
            <input
              type="checkbox"
              id="customPickup"
              name="customPickup"
              checked={customPickup}
              onChange={(e) => handleCustomPickup()}
            />
            <label htmlFor={"customPickup"}>
              I need to schedule a custom pick-up time in Parsons.
            </label>
          </div>
          {customPickup && (
            <div>
              <hr />
              <p>
                <i>
                  Not available for pick-up at these times? Let us know and we
                  can help you schedule another time:
                </i>
              </p>
              <input
                className="pickupNotes"
                type="text"
                placeholder="Enter Pickup Notes"
                onChange={(e) => setCustomPickupNotes(e.target.value)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

const handlePickupTimes = (pickupTimes) => {
  if (pickupTimes) {
    const pickupTimesArray = pickupTimes.flatMap((location) => {
      if (!location.open) {
        return [];
      }
      return location.windows.flatMap((window) => {
        if (!window.open) {
          return [];
        }

        return {
          location: location.location,
          time: window.time,
          slotRef: { id: location.id, slotNum: window.slotNum },
        };
      });
    });

    return pickupTimesArray;
  }
};
