import "./Checkout.css";

import { useEffect, useState, useCallback } from "react";
import { useCart } from "../../hooks/useCart";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PayForm from "./PayForm";

//custom hooks
import { useAuthContext } from "../../hooks/useAuthContext";
import getAuthHeaders from "../../utils/getAuthHeaders";

export default function Checkout() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const { cartTotal, itemCount, cart, fulfillment } = useCart();
  const { user } = useAuthContext();
  const [errorMessage, setErrorMessage] = useState(null);

  const taxRate = fulfillment ? fulfillment.taxRate : null;

  const handleCartTotal = useCallback(() => {
    const cartSubtotal =
      fulfillment.type === "Delivery"
        ? cartTotal + fulfillment.deliveryFee
        : cartTotal;
    const totalWithTax = (taxRate.food + 10000) / Math.pow(10, 6); //tax rates are stored as 675 for 6.75% and 925 for 9.25%
    return Number.parseFloat(cartSubtotal * totalWithTax + 0.0001).toFixed(2); //0.0001 helps prevent rounding errors
  }, [cartTotal, fulfillment, taxRate]);

  const handleTaxTotal = () => {
    const cartSubtotal =
      fulfillment.type === "Delivery"
        ? cartTotal + fulfillment.deliveryFee
        : cartTotal;
    const foodTax = taxRate.food / Math.pow(10, 6); //tax rates are stored as 675 for 6.75% and 925 for 9.25%
    return Number.parseFloat(cartSubtotal * foodTax + 0.0001).toFixed(2); //0.0001 helps prevent rounding errors
  };

  const configUrl =
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_NETWORK}:4000/api/checkout/config`
      : "/api/checkout/config";

  useEffect(() => {
    const getStripeKey = async () => {
      try {
        const authHeader = await getAuthHeaders(user);

        const response = await fetch(configUrl, { headers: authHeader });

        const { publishableKey } = await response.json();

        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        console.error(error);
      }
    };
    getStripeKey();
  }, [user, configUrl]);

  //Exclude out of stock items and then create a paymentIntent
  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        if (taxRate) {
          //create updated cart that excluded out of stock items
          const updatedCart = cart.filter(
            (product) =>
              product.quantityAvailable > 0 &&
              product.inventoryStatus === "include"
          );

          const paymentIntentBody = {
            cart: updatedCart,
            fulfillment,
            taxRate,
            cartTotal: handleCartTotal(),
            uid: user.uid,
          };

          // Create PaymentIntent as soon as the page loads

          const paymentIntentUrl =
            process.env.NODE_ENV === "development"
              ? `${process.env.REACT_APP_NETWORK}:4000/api/checkout/create-payment-intent`
              : "/api/checkout/create-payment-intent";

          const authHeader = await getAuthHeaders(user);

          const response = await fetch(paymentIntentUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...authHeader,
            },
            body: JSON.stringify(paymentIntentBody),
          });

          const { clientSecret } = await response.json();

          setClientSecret(clientSecret);

          if (response.status !== 200) {
            setErrorMessage(
              response.status === 405
                ? "Max amount that can be charged with credit card is $3,000. Please call +1 (620) 878-0298 to place your order."
                : `${response.status} - ${response.statusText}`
            );
          } else {
            setErrorMessage(null);
          }
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(error.statusText);
      }
    };
    createPaymentIntent();
  }, [cart, fulfillment, user, taxRate, handleCartTotal]);

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#359356",
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  const handleCustomPickup = () => {
    return fulfillment.customPickup ? (
      <span>
        <b>• Custom Pick-up:</b> we will contact you to schedule a custom
        pick-up time
      </span>
    ) : (
      <span>
        <b>• Your pick-up is scheduled for</b> {fulfillment.fulfillmentDate}.
        We'll let you know if anything changes.
      </span>
    );
  };

  //recording cart at checkout to check for errors
  const handleErrorCheck = async () => {
    try {
      const orderBody = {
        orderState: "Error Check",
        cart,
        fulfillment,
        taxRate,
        cartTotal: handleCartTotal(),
        uid: user.uid,
        timestamp: new Date(),
      };

      // Define the API URL based on the environment
      const apiUrl =
        process.env.NODE_ENV === "development"
          ? `${process.env.REACT_APP_NETWORK}:4000/api/orders/add`
          : "/api/orders/add";

      const authHeader = await getAuthHeaders(user);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...authHeader,
        },
        body: JSON.stringify(orderBody),
      });

      if (!response.ok) {
        // Handle the error here, e.g., throw an exception or log it
        throw new Error(`Error during request: ${response.statusText}`);
      }
    } catch (error) {
      // Handle errors that occur during the request or processing
      console.error(error);
    }
  };

  const CheckoutNotes = () => {
    return (
      <div className="checkout-notes">
        <h3>Important note!</h3>
        <ul>
          <li className="checkout-li">
            <p>
              <b>
                • Items that currently show in stock may become unavailable.{" "}
              </b>{" "}
              If an item becomes unavailable after you place your order, we will
              notify you and adjust your order total accordingly.
            </p>
          </li>
          {fulfillment.type === "Pickup" ? (
            <li className="checkout-li">{handleCustomPickup()}</li>
          ) : (
            <></>
          )}
          {fulfillment.type === "Delivery" ? (
            <li className="checkout-li">
              <b>• Your delivery is scheduled for</b>{" "}
              {fulfillment.fulfillmentDate}. We'll let you know if that changes.
            </li>
          ) : (
            <></>
          )}
        </ul>
      </div>
    );
  };

  const SubTotal = () => {
    return (
      <div className="subtotal">
        <div className="checkoutBox">
          <p>
            <b>Subtotal</b> ({itemCount} {itemCount > 1 ? "items" : "item"})
          </p>
          <p>${Number.parseFloat(cartTotal / 100).toFixed(2)}</p>
        </div>
        {fulfillment.type === "Delivery" && (
          <div className="checkoutBox">
            <p>Delivery</p>
            <p>${Number(fulfillment.deliveryFee / 100).toFixed(2)}</p>
          </div>
        )}
        {fulfillment.type === "Pickup" && (
          <div className="checkoutBox">
            <p>Pickup</p>
            <p>Free</p>
          </div>
        )}
        <div className="checkoutBox">
          <p>Estimated taxes</p>
          {taxRate && <p>${handleTaxTotal()}</p>}
        </div>
        <div className="checkoutBox">
          <p>
            <b>Estimated total</b>
          </p>
          {taxRate && <p>${handleCartTotal()}</p>}
        </div>
      </div>
    );
  };

  const CheckoutForm = () => {
    return (
      <div className="checkoutForm">
        {stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <PayForm
              formattedCartTotal={handleCartTotal()}
              handleErrorCheck={handleErrorCheck}
            />
          </Elements>
        )}
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    );
  };

  return (
    <div className="checkout-page grid">
      <CheckoutNotes />
      <CheckoutForm />
      <SubTotal />
    </div>
  );
}
