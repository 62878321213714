import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useCart } from "../../hooks/useCart";
import { useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

//components
import CheckoutFooter from "./CheckoutFooter";

//images
import groceryBag from "../../components/images/GroceryBag.PNG";

//styles
import "./Confirmation.css";

export default function Confirmation() {
  const navigate = useNavigate();

  return (
    <div className="confirmation">
      <FontAwesomeIcon
        className="confirmation-checkmark"
        icon={faCheckCircle}
      />
      <h3>Important notes!</h3>
      <p className="confirmation-text">
        Sometimes items that currently show in stock become unavailable.
      </p>
      <p className="confirmation-text">
        {" "}
        If an item becomes unavailable after you place your order, we will
        notify you and adjust your order total accordingly.
      </p>
      <img src={groceryBag} alt="Grocery Bag" className="confirmation-image" />
    </div>
  );
}
